import { Route, Routes } from 'react-router-dom';
import { appRoutes } from './navigation';

function AppRoutes() {
  return (
    <Routes>
      {
        appRoutes.map((r, i) => <Route key={i} path={r.path} element={r.element}/>)
      }
    </Routes>
  );
}

export default AppRoutes;
