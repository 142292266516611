import NavBar from "./NavBar";

function Page({ background, children }) {
  return (
    <>
      <div
        style={{
          backgroundImage: `url(${background})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          width: "100vw",
          height: "100vh",
        }}
      >
        <NavBar />
      </div>
      <div>{children}</div>
    </>
  );
}

export default Page;
