function NavBar() {
  return (
    <nav
      className="navbar navbar-expand-lg"
      style={{
        backgroundColor: "transparent",
        fontFamily: "Raleway",
      }}
    >
      <div className="container-fluid">
        <a
          className="navbar-brand"
          style={{
            fontSize: "1.5rem",
            color: "white",
          }}
          href="/"
        >
          mdsullivan
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
      </div>
    </nav>
  );
}

export default NavBar;
